import React from 'react';
import Form from '../components/forms/Form';
import Head from '../components/Head';
import Guard from '../components/guards/Guard';
import { forgetPassword } from '../api/users.api';
import { useHistory } from "react-router-dom";
import Button from "../components/Button"

const ForgetPassword = () => {
  const history = useHistory();
  const [sent, setSent] = React.useState(false);

  return (
    <React.Fragment>
      <Head title='Forget Password' path='/forgetPassword' />
      <Guard condition={sent} fallback={
        <Form
          title='Forget Password'
          description='Please enter the email address for the account that you would like to reset the password for'
          inputs={[
            {
              name: 'email',
              type: 'email',
              required: true
            }
          ]}
          apiRequest={forgetPassword}
          callback={() => { setSent(true) }}
        />
      }>
        <h3>An email has been sent to you. Please follow the instructions to reset your password.</h3>
      </Guard>
      <Button label="back" color='primary' handleClick={history.goBack} style={{width:'50px'}}/>
    </React.Fragment>
  )
}

export default ForgetPassword;
