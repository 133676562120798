import { OpenInNew } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import api from "../api/applicationProcesses.api";
import programFormsApi from "../api/programForms.api";
import CollectionComponent from "../components/CollectionComponent/CollectionComponent";
import RenderBoolean from "../components/CollectionComponent/CustomRender/RenderBoolean";
import RenderDate from "../components/CollectionComponent/CustomRender/RenderDate";
import PermissionsGuard from "../components/guards/PermissionsGuard";
import SingleComponent from "../components/SingleCompnent/SingleComponent";
import { PermissionsEnum } from "../util/enum/permissions.enum";
import extractErrorText, { Err } from "../util/functions/extractErrorText";

interface IFormQuestion {
  question: string;
  answer: string;
}

interface IApplication {
  id: number;
  reviewed: boolean;
  accepted: boolean;
  submissionDate: Date;
  form: IFormQuestion[];
}

interface IApplicationProcess {
  id?: number;
  programOffering: any;
  applications?: IApplication[];
  name: string;
  additionalFormId: string;
  startDate: Date;
  endDate: Date;
  archived?: boolean;
}

const ApplicationProcess: React.FunctionComponent<{
  children?: never;
}> = () => {
  const id = useParams<{ id: string }>().id;
  const history = useHistory();

  const [processData, setProcessData] = useState<IApplicationProcess>();
  const [refreshWanted, setRefresh] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  function refresh() {
    setRefresh(refreshWanted + 1);
  }

  useEffect(() => {
    (async () => {
      try {
        // Fetch the process data
        let { data } = await api.findOne(+id);
        setProcessData(data);

        // Fetch the forms data
        const forms = await programFormsApi.adminGetSubmissionsByIds(
          data.applications.map((application: any) => application.id)
        );

        // Merge the forms data into the applications
        const mergedApplications = data.applications.map((application: any) => {
          const formData = forms.data.find(
            (form: any) => form.id === application.id
          );
          return {
            ...application,
            form: formData ? flattenForm(formData.form) : {},
          };
        });

        // Update the state with the merged data
        setProcessData((prevData: any) => ({
          ...prevData,
          applications: mergedApplications,
        }));
      } catch (error) {
        console.error("Error fetching or merging data:", error);
      }
    })();
  }, [refreshWanted]);

  // Helper function to flatten the form data
  const flattenForm = (form: any) => {
    if (!form || !form.sections) return {};

    const flattenedForm: { [key: string]: any } = {};
    form.sections.forEach((section: any) => {
      if (section.questions) {
        section.questions.forEach((question: any) => {
          flattenedForm[question._id] = question;
        });
      }
    });

    return flattenedForm;
  };

  return (
    <>
      <SingleComponent
        title="Application Process"
        fields={[
          {
            name: "Name",
            value: processData?.name,
          },
          {
            name: "Start Date",
            value: RenderDate(["startDate"])(processData),
          },
          {
            name: "End Date",
            value: RenderDate(["endDate"])(processData),
          },
        ]}
      />
      <br />
      <PermissionsGuard
        requiredPermissions={[PermissionsEnum.ViewApplications]}
      >
        <CollectionComponent
          title="Applications"
          data={processData?.applications || []}
          loading={!processData}
          columns={[
            {
              title: "Student first name",
              field: "studentProfile.user.firstName",
            },
            {
              title: "Student last name",
              field: "studentProfile.user.lastName",
            },
            {
              title: "Student email",
              field: "studentProfile.user.email",
            },
            {
              title: "Reviewed",
              render: RenderBoolean(["reviewed"]),
              field: "reviewed",
            },
            {
              title: "Accepted",
              render: RenderBoolean(["accepted"]),
              field: "accepted",
            },
            {
              title: "Submission Date",
              render: RenderDate(["submissionDate"]),
              field: "submissionDate",
            },

            // Add new fields using map
            ...Object.entries(processData?.applications?.[0]?.form ?? {}).map(
              ([key, field]: [string, any]) => ({
                title: field.question,
                field: `form.${field._id}.defaultValue`,
                hidden: true,
                export: true,
              })
            ),
          ]}
          viewOnly
          actions={[
            {
              name: "Accept",
              icon: "check",
              onClick: async (_e, data: IApplication) => {
                try {
                  let confirmed = window.confirm(
                    `Are you sure you want to accept this student's application?`
                  );
                  if (!confirmed) return;
                  await api.accept(data.id);
                  refresh();
                } catch (e) {
                  enqueueSnackbar(await extractErrorText(e as Err), {
                    variant: "error",
                  });
                  refresh();
                }
              },
              tooltip: "Accept application",
              condition: (data: IApplication) =>
                !data.reviewed && data.submissionDate,
              requiredPermission: PermissionsEnum.AcceptApplication,
            },
            {
              name: "Reject",
              icon: "close",
              onClick: async (_e, data: IApplication) => {
                let confirmed = window.confirm(
                  `Are you sure you want to reject this student's application?`
                );
                if (!confirmed) return;
                await api.reject(data.id);
                refresh();
              },
              tooltip: "Reject application",
              condition: (data: IApplication) =>
                !data.reviewed && data.submissionDate,
              requiredPermission: PermissionsEnum.RejectApplication,
            },
            {
              name: "View",
              icon: OpenInNew,
              requiredPermission: PermissionsEnum.ViewStudentApplication,
              onClick: (_e, data: IApplication) => {
                history.push(`/application/${data.id}`);
              },
            },
          ]}
        />
      </PermissionsGuard>
    </>
  );
};

export default ApplicationProcess;
