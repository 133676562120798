import React, { useEffect, useRef, useState } from "react";
import MaterialTable, {
  Action,
  Column,
  MTableAction,
  MTableEditRow,
} from "material-table";
import "../../styles/MaterialTable.scss";
import "../../styles/TableFilters.scss";
import "../../styles/ButtonsSection.scss";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Form from "../forms/Form";
import Guard from "../guards/Guard";
import { MTableBodyRow } from "material-table";
import { makeStyles } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { PermissionsEnum } from "../../util/enum/permissions.enum";
import PermissionsGuard from "../guards/PermissionsGuard";
import { CheckPermissions } from "../guards/ComponentGuard";
import extractErrorText, { Err } from "../../util/functions/extractErrorText";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { connect } from "react-redux";
import findOptions from "../../api/interfaces/findOptions.interface";
import { AxiosResponse } from "axios";
import { FileDownload } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import contentDisposition from "content-disposition";
import { ExportFormat } from "../../api/base.api";
import { ExportModal } from "../ExportModal";

function keyString(str: string, data: any) {
  if (!str) return;
  let keys = str.split("."); // [user, isBanned]
  let output = data;
  for (let k of keys) {
    // user, isBanned
    output = output[k];
  }
  return output;
}
interface CustomComponents {
  Pagination?: any;
  Action?: any;
  Row?: any;
  EditRow?: any;
  Body?: any;
}

interface IAction<T extends object> extends Action<T>, Record<string, unknown> {
  requiredPermission?: PermissionsEnum | boolean;
}

interface CollectionComponentProps {
  data: any[];
  columns: Column<any>[];
  loading: boolean;
  title: string;
  backendPagination?: boolean;
  backendSearch?: boolean;
  withFilters?: boolean;
  page?: number;
  pagesCount?: number;
  pageSize?: number;
  onPageChange?: Function;
  onPageSizeChange?: Function;
  onRowUpdate?: Function;
  onRowEditCancelled?: Function;
  onRowDelete?: Function;
  actions?: (IAction<any> | ((rowData: any) => IAction<any>))[];
  searchText?: string;
  onSearchChange?: Function;
  itemName?: string;
  deleteText?: string;
  customComponents?: CustomComponents;
  disableKey?: string;
  hideKey?: string;
  viewOnly?: boolean;
  noDelete?: boolean;
  noEdit?: boolean;
  export?:
    | {
        type: "custom";
        fn: (
          options: findOptions,
          format: ExportFormat
        ) => Promise<AxiosResponse>;
        formats?: ExportFormat[];
      }
    | {
        type: "builtin";
      }
    | false;
  mainPermission?: PermissionsEnum;
  tableRef?: React.MutableRefObject<any>;
  viewDisabledInSeparateTab?: boolean;
  onHideChange?: Function;
  expandTableAt?: number;
  renderRow?: Function;
  deletePermission?: PermissionsEnum;
  viewDisabledPermission?: PermissionsEnum | boolean;
  userPermissions: PermissionsEnum[];
  isLoggedIn: boolean;
  isEditHidden?: (rowData: any) => boolean;
}

const CollectionComponent: React.FunctionComponent<CollectionComponentProps> = (
  props
) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = makeStyles({
    row: {
      opacity: 0.5,
    },
  })();
  let [hideStatus, setHideStatus] = useState(false);
  const [exportModelOpen, setExportModelOpen] = React.useState(false);

  let customComponents: CustomComponents = {
    Row: (p: any) => {
      const { data } = p;
      if (props.renderRow) return props.renderRow(p);
      if (props.hideKey && keyString(props.hideKey, data)) {
        return <React.Fragment />;
      } else if (props.disableKey && keyString(props.disableKey, data)) {
        return <MTableBodyRow className={classes.row} {...p} />;
      }
      return <MTableBodyRow {...p} />;
    },
    EditRow: (p: any) => {
      return (
        <MTableEditRow
          {...p}
          onEditingCanceled={(mode: any, rowData: any) => {
            if (props.onRowEditCancelled) props.onRowEditCancelled(rowData);
            p.onEditingCanceled(mode);
          }}
        />
      );
    },
    Action: (p: any) => {
      if (p?.action?.render) {
        return p.action.render(p);
      }

      let permissions: PermissionsEnum[] = [];
      if (p?.action?.requiredPermission) {
        permissions = [p?.action?.requiredPermission];
      } else if (
        p?.action?.requiredPermission !== false &&
        props.mainPermission
      ) {
        permissions = [props.mainPermission];
      }
      if (p?.action?.iconCondition) {
        p.action.icon = p.action.iconCondition()
          ? p.action.icon
          : p.action.secondaryIcon;
      }
      if (p?.action?.condition) {
        return (
          <Guard
            condition={p.action.condition(p.data)}
            fallback={<React.Fragment />}
          >
            <PermissionsGuard requiredPermissions={permissions}>
              <MTableAction {...p} />
            </PermissionsGuard>
          </Guard>
        );
      }
      return (
        <PermissionsGuard requiredPermissions={permissions}>
          <MTableAction {...p} />
        </PermissionsGuard>
      );
    },
    ...props.customComponents,
  };
  let actions: (IAction<any> | ((rowData: any) => IAction<any>))[] = [];

  if (props.export && props.export?.type === "custom") {
    actions.push({
      icon: () => <FileDownload />,
      tooltip: "Export",
      onClick() {
        setExportModelOpen(true);
      },
      isFreeAction: true,
    });
  }

  if (props.viewDisabledInSeparateTab) {
    actions.push({
      icon: () => <VisibilityIcon />,
      tooltip: "Toggle view disabled",
      onClick: () => {
        if (hideStatus) {
          if (props.onHideChange) props.onHideChange();
        } else {
          if (props.onHideChange && props.disableKey)
            props.onHideChange(props.disableKey);
        }
        setHideStatus(!hideStatus);
      },
      requiredPermission: props.viewDisabledPermission,
      iconCondition: () => hideStatus,
      secondaryIcon: VisibilityOffIcon,
      isFreeAction: true,
    });
  }
  actions = actions.concat(props.actions || []);

  let deleteText;
  if (props.deleteText) deleteText = props.deleteText;
  else if (props.itemName) {
    deleteText = `Are you sure you want to delete this ${props.itemName}?`;
  }

  actions.map((action: any) => {
    if (action?.onClick) {
      let actualOnClick = action.onClick;
      action.onClick = async (e: any, data: any) => {
        try {
          let response: any = await actualOnClick(e, data);
          if (response?.data && typeof response.data === "string") {
            enqueueSnackbar(response.data, {
              variant: "success",
            });
          }
        } catch (e) {
          let errorText: string = await (action.errorMessage
            ? action.errorMessage(e)
            : extractErrorText(e as Err));
          enqueueSnackbar(errorText, {
            variant: "error",
          });
        }
      };
    }
    return action;
  });

  let tableEdits: any = {
    isEditHidden: props.isEditHidden,
    onRowDelete: async (oldData: any) => {
      if (props.onRowDelete) {
        try {
          let response = await props.onRowDelete(oldData);
          if (response?.data && typeof response.data === "string") {
            enqueueSnackbar(response.data, {
              variant: "success",
            });
          }
          return response;
        } catch (err) {
          let msg = await extractErrorText(err as any);
          enqueueSnackbar(msg, {
            variant: "error",
          });
        }
      } else {
        return new Promise((resolve) => {
          console.log(oldData, resolve);
          resolve(null);
        });
      }
    },
    onRowUpdate: async (newData: any, oldData: any) => {
      if (props.onRowUpdate) {
        try {
          let response = await props.onRowUpdate(newData, oldData);
          if (response?.data && typeof response.data === "string") {
            enqueueSnackbar(response.data, {
              variant: "success",
            });
          }
          return response;
        } catch (error) {
          let message: string = await extractErrorText(error as Err);
          enqueueSnackbar(message, { variant: "error" });
        }
      } else {
        return new Promise((resolve) => {
          console.log(newData, oldData);
          resolve(null);
        });
      }
    },
  };

  if (props.deletePermission) {
    if (
      !CheckPermissions(
        props.userPermissions || [],
        props.deletePermission ? [props.deletePermission] : []
      )
    )
      tableEdits.isDeleteHidden = () => true;
  }

  if (props.backendSearch) {
    props.columns.forEach((col) => (col.customFilterAndSearch = () => true));
  }

  if (props.noDelete) {
    tableEdits.isDeleteHidden = () => true;
  }

  if (props.noEdit) {
    tableEdits.isEditHidden = () => true;
  }

  if (props.viewOnly) {
    tableEdits.isDeleteHidden = () => true;
    tableEdits.isEditHidden = () => true;
  }
  const [compact, setCompact] = useState(false);

  let backendProps = {};
  const pageSize = props.backendPagination ? props.pageSize || 10 : 5;
  const tableRef = useRef<any>();
  if (props.tableRef) {
    props.tableRef.current = tableRef.current;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (
      tableRef.current.dataManager.filteredData.length <
      (props.expandTableAt || Math.ceil(pageSize / 2))
    ) {
      compact || setCompact(true);
    } else {
      compact && setCompact(false);
    }
  });

  if (props.backendPagination) {
    backendProps = {
      onChangePage: (page: number) => {
        props.onPageChange?.(page + 1);
      },
      onChangeRowsPerPage: (size: number) => {
        props.onPageSizeChange?.(size);
      },
      totalCount: props.pagesCount || 0,
      page: (props.page || 1) - 1,
    };
  }

  return (
    <React.Fragment>
      <Guard condition={props.withFilters || false}>
        <div className="collection-filters">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Filters</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="filters-content">
                <Form
                  inputs={[
                    {
                      type: "text",
                      required: true,
                      name: "email",
                    },
                    {
                      type: "text",
                      required: true,
                      name: "name",
                    },
                  ]}
                />
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </Guard>
      <div className="material-table" style={{ maxWidth: "100%" }}>
        <MaterialTable
          onSearchChange={(text) => {
            if (props.onSearchChange) {
              props.onSearchChange(text);
              return;
            }
          }}
          tableRef={tableRef}
          {...backendProps}
          isLoading={props.loading}
          options={{
            tableLayout: "auto",
            debounceInterval: 500,
            pageSize,
            pageSizeOptions: [5, 10, 25, 50, 100],
            searchText: props.searchText,
            exportButton:
              props.export === undefined ||
              (props.export !== false && props.export?.type === "builtin"),
            paginationType: "normal",
            emptyRowsWhenPaging: !compact,
          }}
          columns={props.columns}
          data={props.data}
          title={props.title}
          editable={tableEdits}
          actions={actions}
          components={customComponents}
          localization={{
            header: { actions: "" },
            body: {
              editRow: {
                ...(deleteText && { deleteText }),
              },
            },
          }}
        />
      </div>
      <Guard condition={props.export && props.export?.type === "custom"}>
        <ExportModal
          modalOpen={exportModelOpen}
          onClose={() => setExportModelOpen(false)}
          formats={(props.export as any)?.formats}
          exportFn={async function (data) {
            // Create the export options object
            const exportOptions = {
              page: props.page,
              page_size: props.pageSize,
              query: props.searchText,
              columns: props.columns,
              ...((hideStatus && { view: props.disableKey }) || {}),
            };

            // Call the export function with both the options and the format
            return await (props.export as any).fn(exportOptions, data.format);
          }}
        />
      </Guard>
    </React.Fragment>
  );
};

const mapStateToProps = (store: any) => {
  return {
    isLoggedIn: store.UserReducer.isLoggedIn,
    userPermissions: store.UserReducer.permissions,
  };
};

export default connect(mapStateToProps)(CollectionComponent);
