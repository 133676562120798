import axios from "axios";

const getProgramForm = (id: number) => {
  return axios.get(`/programs/${id}/form`);
};

const editProgramForm = (id: number, data: any) => {
  return axios.patch(`/programs/${id}/form`, data);
};

const submitProgramApplicationForm = (id: number, data: any) =>
  axios.patch(`/applicationProcesses/${id}/applicationForm`, data);

const adminGetStudentApplication = (applicationId: number) =>
  axios.get(`/applicationProcesses/applicationForm/${applicationId}`);

const getStudentApplication = (id: number) =>
  axios.get(`/applicationProcesses/${id}/applicationForm`);

const adminGetSubmissionsByIds = (applicationIds: number[]) =>
  axios.post(`/applicationProcesses/applicationForms`, { applicationIds });

const forceSubmitApplication = (id: number) =>
  axios.post(`/applicationProcesses/submit/${id}`);

export const getApplicationReferrals = (id: number) =>
  axios.get(`/applicationProcesses/application/${id}/referrals`);

export default {
  getProgramForm,
  editProgramForm,
  submitProgramApplicationForm,
  getStudentApplication,
  adminGetStudentApplication,
  forceSubmitApplication,
  getApplicationReferrals,
  adminGetSubmissionsByIds,
};
