import { OpenInNew } from "@material-ui/icons";
import axios, { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import api from "../api/programs.api";
import Button from "../components/Button";
import CollectionComponent from "../components/CollectionComponent/CollectionComponent";
import RenderDate from "../components/CollectionComponent/CustomRender/RenderDate";
import ComponentGuard from "../components/guards/ComponentGuard";
import PermissionsGuard from "../components/guards/PermissionsGuard";
import Head from "../components/Head";
import SingleComponent from "../components/SingleCompnent/SingleComponent";
import { PermissionsEnum } from "../util/enum/permissions.enum";
import { SemesterEnum } from "../util/enum/semester.enum";
import extractErrorText, { Err } from "../util/functions/extractErrorText";
import { handleResponseError, toOptions } from "./recipesDatabase/utils";
import { getAllGradingSchemas } from "../api/gradingSchemas.api";
import { InputFactory } from "../components/forms/inputs/InputFactory";
import { useForm } from "react-hook-form";
import videoApi from "../api/videos.api";
import admissionRequirementApi from "../api/admissionRequirements.api";
import faqApi from "../api/faqs.api";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { MTableAction } from "material-table";
import { find as findReferralForms } from "../api/referralForms.api";
import useFindRequestQueryParams from "../hooks/useFindRequestQueryParams.hook";
import { useQuery } from "react-query";

const Program = () => {
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState({} as any);
  const [courses, setCourses] = useState([]);
  const [gradingSchemas, setGradingSchemas] = useState([]);
  const [semesterEnums, setSemesterEnums] = useState(
    [] as { label: string; value: string | number }[]
  );
  const [faqGroups, setFaqGroups] = useState<any[]>([]);
  const [admissionRequirements, setAdmissionRequirements] = useState<any[]>([]);
  const [referralFormOptions, setReferralFormOptions] = useState<any[]>([]);
  const [videos, setVideos] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const getVideos = async () => {
    let {
      data: { data: videoData },
    } = await videoApi.getAll();
    setVideos(videoData || []);
  };

  const getAdmissionRequirements = async () => {
    let {
      data: { data: admissionRequirementData },
    } = await admissionRequirementApi.getAll();
    setAdmissionRequirements(admissionRequirementData || []);
  };

  useQuery("referralForms", () => findReferralForms(), {
    onSuccess(response) {
      setReferralFormOptions(
        response.data.data.map((form: any) => ({
          label: form.name,
          value: form.id,
        }))
      );
    },
  });

  useQuery("faqGroups", faqApi.getAllGroups, {
    onSuccess(response) {
      setFaqGroups(response.data.data);
    },
  });

  let [
    programCourseData,
    programCoursePagesCount,
    programCourseLoading,
    programCoursePage,
    programCoursePageSize,
    programCourseSearch,
    programCourseOnPageChange,
    programCourseOnPageSizeChange,
    programCourseOnSearchChange,
    programCourseRefresh,
    programCourseOnHideChange,
  ] = useFindRequestQueryParams(
    api.getProgramCourses(+id),
    "programCourses",
    "courses"
  );

  useEffect(() => {
    if (programCourseData)
      for (let programCourse of programCourseData) {
        for (let translation of programCourse.course.translations) {
          programCourse[`${translation.language}Name`] = translation.name;
          programCourse[`${translation.language}Description`] =
            translation.description;
        }
      }
  }, [programCourseData]);

  let [
    concentrationData,
    concentrationPagesCount,
    concentrationLoading,
    concentrationPage,
    concentrationPageSize,
    concentrationSearch,
    concentrationOnPageChange,
    concentrationOnPageSizeChange,
    concentrationOnSearchChange,
    concentrationRefresh,
    concentrationOnHideChange,
  ] = useFindRequestQueryParams(
    api.getProgramConcentrations(+id),
    "programConcentrations",
    "concentrations"
  );

  useEffect(() => {
    if (concentrationData)
      for (let concentration of concentrationData) {
        for (let translation of concentration.translations) {
          concentration[`${translation.language}Name`] = translation.name;
          concentration[`${translation.language}Description`] =
            translation.description;
        }
      }
  }, [concentrationData]);

  let [
    programOfferingsData,
    programOfferingsPagesCount,
    programOfferingsLoading,
    programOfferingsPage,
    programOfferingsPageSize,
    programOfferingsSearch,
    programOfferingsOnPageChange,
    programOfferingsOnPageSizeChange,
    programOfferingsOnSearchChange,
    programOfferingsRefresh,
    programOfferingsOnHideChange,
  ] = useFindRequestQueryParams(
    api.getProgramOfferings(+id),
    "programOfferings",
    "oferings",
    true
  );

  let [
    programFaqGroupData,
    programFaqGroupPagesCount,
    programFaqGroupLoading,
    programFaqGroupPage,
    programFaqGroupPageSize,
    programFaqGroupSearch,
    programFaqGroupOnPageChange,
    programFaqGroupOnPageSizeChange,
    programFaqGroupOnSearchChange,
    programFaqGroupRefresh,
    programFaqGroupOnHideChange,
  ] = useFindRequestQueryParams(
    api.getProgramFaqGroups(+id),
    "programFaqGroups",
    "faqGroups"
  );

  // useEffect(() => {
  //   if (programFaqData)
  //     for (let faq of programFaqData) {
  //       for (let translation of faq.translations) {
  //         faq[`${translation.language}Question`] = translation.question;
  //         faq[`${translation.language}Answer`] = translation.answer;
  //       }
  //     }
  // }, [programFaqData])

  let [
    programAdmissionRequirementData,
    programAdmissionRequirementPagesCount,
    programAdmissionRequirementLoading,
    programAdmissionRequirementPage,
    programAdmissionRequirementPageSize,
    programAdmissionRequirementSearch,
    programAdmissionRequirementOnPageChange,
    programAdmissionRequirementOnPageSizeChange,
    programAdmissionRequirementOnSearchChange,
    programAdmissionRequirementRefresh,
    programAdmissionRequirementOnHideChange,
  ] = useFindRequestQueryParams(
    api.getProgramAdmissionRequirements(+id),
    "programAdmissionRequirements",
    "admissionRequirements"
  );

  useEffect(() => {
    if (programAdmissionRequirementData)
      for (let admissionRequirement of programAdmissionRequirementData)
        for (let translation of admissionRequirement.translations)
          admissionRequirement[`${translation.language}Text`] =
            translation.text;
  }, [programAdmissionRequirementData]);

  let [
    programVideoData,
    programVideoPagesCount,
    programVideoLoading,
    programVideoPage,
    programVideoPageSize,
    programVideoSearch,
    programVideoOnPageChange,
    programVideoOnPageSizeChange,
    programVideoOnSearchChange,
    programVideoRefresh,
    programVideoOnHideChange,
  ] = useFindRequestQueryParams(
    api.getProgramVideos(+id),
    "programVideos",
    "videos"
  );

  const { control, errors } = useForm<{}>();

  useEffect(() => {
    setSemesterEnums(
      Object.values(SemesterEnum).map((s) => ({
        value: s,
        label: s,
      }))
    );
    (async () => {
      // Get courses
      let {
        data: { data: courses },
      } = await axios.get("/courses/all");

      setCourses(
        toOptions(courses, ["translations"], undefined, [
          "code",
          "department_id",
        ])
      );

      // Get schemas
      let {
        data: { data: schemas },
      } = await getAllGradingSchemas();

      setGradingSchemas(
        schemas.map((s: any) => ({
          label: s.name,
          value: s.id,
        }))
      );
    })();
    getVideos();
    getAdmissionRequirements();
    loadProgram();
  }, []);

  async function loadProgram() {
    try {
      setLoading(true);
      const program = await api.get(+id);
      parseProgram(program);
      setLoading(false);
    } catch (error) {
      enqueueSnackbar(await extractErrorText(error as Err), {
        variant: "error",
      });
      history.push("/");
    }
  }

  async function parseProgram(response: AxiosResponse) {
    const { data } = response;

    for (let translation of data.translations) {
      data[`${translation.language}Name`] = translation.name;
      data[`${translation.language}Description`] = translation.description;
    }

    setData(data);
  }

  const refresh = loadProgram;

  return (
    <React.Fragment>
      <Head title="Program" path="/program" />
      <div className="buttons-section">
        <PermissionsGuard
          requiredPermissions={[PermissionsEnum.ModifyProgramGradingSchemas]}
        >
          <Button
            color="primary"
            label="Add grading schema"
            withForm
            form={{
              title: "Add grading schema",
              inputs: [
                {
                  type: "select",
                  name: "Grading schema",
                  keyName: "gradingSchemaId",
                  required: true,
                  options: gradingSchemas,
                },
                {
                  type: "select",
                  name: "From Semester",
                  keyName: "fromSemesterEnum",
                  required: false,
                  options: semesterEnums,
                },
                {
                  type: "number",
                  name: "From Year",
                  keyName: "fromSemesterYear",
                  required: false,
                },
                {
                  type: "select",
                  name: "To Semester",
                  keyName: "toSemesterEnum",
                  required: false,
                  options: semesterEnums,
                },
                {
                  type: "number",
                  name: "To Year",
                  keyName: "toSemesterYear",
                  required: false,
                },
                {
                  type: "checkbox",
                  name: "Set as default",
                  keyName: "setAsDefault",
                  required: false,
                },
              ],
              apiRequest: (data: any) => api.changeGradingSchema(+id, data),
              successMessage: () => {
                refresh();
                return "Grading schema added successfully!";
              },
              errorMessage: extractErrorText,
            }}
          />
        </PermissionsGuard>
        <PermissionsGuard
          requiredPermissions={[PermissionsEnum.ChangeProgramReferralForm]}
        >
          <Button
            color="primary"
            label="Change referral form"
            withForm
            form={{
              title: "Change referral form",
              inputs: [
                {
                  type: "select",
                  name: "Referral form",
                  keyName: "referralFormId",
                  required: true,
                  options: referralFormOptions,
                },
              ],
              apiRequest: async (data: any) => {
                await api.changeProgramReferralForm(+id, data);
                await refresh();
              },
              successMessage: () => "Referral form changed successfully",
              errorMessage: extractErrorText,
            }}
          />
        </PermissionsGuard>
        <PermissionsGuard
          requiredPermissions={[PermissionsEnum.OpenCourseForProgram]}
        >
          <Button
            color="primary"
            label="Add program course"
            withForm
            form={{
              title: "Add program course",
              inputs: [
                {
                  type: "select",
                  name: "Course",
                  keyName: "courseKey",
                  required: true,
                  options: courses,
                },
                {
                  type: "select",
                  name: "Is Core?",
                  keyName: "optional",
                  required: false,
                  options: [
                    {
                      label: "No",
                      value: 1,
                    },
                    {
                      label: "Yes",
                      value: 0,
                    },
                  ],
                },
                {
                  type: "checkbox",
                  required: false,
                  name: "Is Under Graduate",
                  keyName: "isUnderGraduate",
                },
              ],
              apiRequest: (data: any) => api.addCourse(+id, data),
              successMessage: () => {
                programCourseRefresh();
                return "Program course created successfully!";
              },
              errorMessage: extractErrorText,
            }}
          />
        </PermissionsGuard>
        <PermissionsGuard
          requiredPermissions={[PermissionsEnum.CreateConcentrations]}
        >
          <Button
            color="primary"
            label="Create Program concentration"
            withForm
            form={{
              title: "Create Program Concentration",
              description:
                "Enter the details of the program concentration you would like to create.",
              apiRequest: (data: any) => api.addConcentration(+id, data),
              inputs: [
                {
                  type: "text",
                  name: "Code",
                  keyName: "code",
                  required: true,
                },
                {
                  type: "decimal",
                  name: "Credit Hours",
                  keyName: "creditHours",
                  required: true,
                },
                {
                  type: "text",
                  name: "English name",
                  keyName: "enName",
                  required: true,
                },
                {
                  type: "text",
                  name: "English description",
                  keyName: "enDescription",
                  required: true,
                },
                {
                  type: "text",
                  name: "Arabic name",
                  keyName: "arName",
                  required: true,
                },
                {
                  type: "text",
                  name: "Arabic description",
                  keyName: "arDescription",
                  required: true,
                },
              ],
              successMessage: () => {
                concentrationRefresh();
                return "Program concentration created successfully!";
              },
              errorMessage: extractErrorText,
              explicitCloseButton: true,
            }}
          />
          <PermissionsGuard
            requiredPermissions={[PermissionsEnum.SetProgramPricePerCreditHour]}
          >
            <Button
              color="primary"
              label="Set price per credit hour"
              withForm
              form={{
                title: "Set price per credit hour",
                inputs: [
                  {
                    type: "number",
                    name: "Price per credit hour",
                    keyName: "pricePerCreditHour",
                    required: true,
                  },
                ],
                apiRequest: (data: any) => api.setPricePerCreditHour(+id, data),
                successMessage: () => {
                  refresh();
                  return "Price per credit hour set successfully!";
                },
                errorMessage: extractErrorText,
              }}
            />
          </PermissionsGuard>
          <PermissionsGuard
            requiredPermissions={[PermissionsEnum.ViewProgramAdditonalForm]}
          >
            <Button
              color="primary"
              label="View Program's Custom Application Fields"
              handleClick={() => history.push(`/program/${id}/form`)}
            />
          </PermissionsGuard>
          <ComponentGuard
            requiredPermissions={[PermissionsEnum.CreateProgramOffering]}
          >
            <Button
              color="primary"
              label="New Program Offering"
              withForm
              form={{
                title:
                  "Enter the details of the program offering you would like to create",
                inputs: [
                  {
                    type: "select",
                    name: "Semester",
                    keyName: "semester",
                    required: true,
                    options: Object.values(SemesterEnum).map((semester) => {
                      return {
                        label: semester,
                        value: semester,
                      };
                    }),
                  },
                  {
                    type: "number",
                    name: "Year",
                    keyName: "year",
                    required: true,
                  },
                  {
                    type: "number",
                    name: "Credit Hours",
                    keyName: "creditHours",
                    required: true,
                  },
                ],
                apiRequest: (data: any) => api.createNewOffering(+id, data),
                successMessage: (res: AxiosResponse) => {
                  programOfferingsRefresh();
                  return res;
                },
                errorMessage: extractErrorText,
              }}
            />
          </ComponentGuard>
        </PermissionsGuard>
        <ComponentGuard
          requiredPermissions={[PermissionsEnum.AddVideoToProgram]}
        >
          <Button
            color="primary"
            label="Add Videos to Program"
            withForm
            form={{
              title: "Add Videos to Program",
              description: "Add videos to this Program.",
              apiRequest: (data: any) => api.addVideosToProgram(+id, data),
              inputs: [
                {
                  name: "Videos",
                  keyName: "vidIds",
                  required: true,
                  type: "multipleSelectAutocomplete",
                  options: videos.map((video) => ({
                    label: video.name,
                    value: video.id,
                  })),
                },
              ],
              successMessage: () => {
                programVideoRefresh();
                return "Video(s) successfully added to Program!";
              },
              errorMessage: handleResponseError,
            }}
          />
        </ComponentGuard>
        <ComponentGuard
          requiredPermissions={[
            PermissionsEnum.AssignAdmissionRequirementToProgram,
          ]}
        >
          <Button
            color="primary"
            label="Add Admission Requirements to Program"
            withForm
            form={{
              title: "Add Admission Requirements to Program",
              description: "Add Admission Requirements to this Program.",
              apiRequest: (data: any) =>
                api.addAdmissionRequirementsToProgram(+id, data),
              inputs: [
                {
                  name: "Admission Requirements",
                  keyName: "admissionRequirementIds",
                  required: true,
                  type: "multipleSelectAutocomplete",
                  options: admissionRequirements.map(
                    (admissionRequirement) => ({
                      label: admissionRequirement.name,
                      value: admissionRequirement.id,
                    })
                  ),
                },
              ],
              successMessage: () => {
                programAdmissionRequirementRefresh();
                return "Admission Requirement(s) successfully added to Program!";
              },
              errorMessage: handleResponseError,
            }}
          />
        </ComponentGuard>
        <ComponentGuard
          requiredPermissions={[PermissionsEnum.AssignFAQGroupToProgram]}
        >
          <Button
            color="primary"
            label="Add FAQ Groups to Program"
            withForm
            form={{
              title: "Add FAQ Groups to Program",
              description: "Add FAQ Groups to this program.",
              apiRequest: (data: any) => api.addFAQGroupsToProgram(+id, data),
              inputs: [
                {
                  name: "FAQ Groups",
                  keyName: "faqGroupIds",
                  required: true,
                  type: "multipleSelectAutocomplete",
                  options: faqGroups.map((faqGroup) => ({
                    label: faqGroup?.name,
                    value: faqGroup.id,
                  })),
                },
              ],
              successMessage: () => {
                programFaqGroupRefresh();
                return "FAQ Group(s) successfully added to Program!";
              },
              errorMessage: handleResponseError,
            }}
          />
        </ComponentGuard>
      </div>
      <SingleComponent
        title="Program"
        fields={[
          {
            name: "Code",
            value: data.code,
          },
          {
            name: "Credit Hours",
            value: data.creditHours,
          },
          {
            name: "English name",
            value: data.enName,
          },
          {
            name: "English description",
            value: data.enDescription,
            displayLineBreaks: true,
            edit: {
              form: {
                title: "Edit english description",
                inputs: [
                  {
                    type: "text",
                    name: "English Description",
                    keyName: "enDescription",
                    required: true,
                    multiline: true,
                    defaultValue: data.enDescription,
                  },
                ],
                apiRequest: async (dataa: any) => {
                  return await api.update(+id, {
                    enName: data.enName,
                    ...dataa,
                  });
                },
                callback: () => refresh(),
                successMessage: () =>
                  "English description edited successfully!",
                errorMessage: extractErrorText,
              },
              editPermissions: [PermissionsEnum.ModifyPrograms],
            },
          },
          {
            name: "Arabic name",
            value: data.arName,
          },
          {
            name: "Arabic description",
            value: data.arDescription,
            displayLineBreaks: true,
            edit: {
              form: {
                title: "Edit arabic description",
                inputs: [
                  {
                    type: "text",
                    name: "Arabic Description",
                    keyName: "arDescription",
                    required: true,
                    multiline: true,
                    defaultValue: data.arDescription,
                  },
                ],
                apiRequest: async (dataa: any) => {
                  return await api.update(+id, {
                    arName: data.arName,
                    ...dataa,
                  });
                },
                callback: () => refresh(),
                successMessage: () => "Arabic description edited successfully!",
                errorMessage: extractErrorText,
              },
              editPermissions: [PermissionsEnum.ModifyPrograms],
            },
          },
          {
            name: "Price per credit hour",
            value: data.pricePerCreditHour,
          },
          {
            name: "Referral form",
            value: data.referralForm?.name,
            permissions: [
              PermissionsEnum.ViewReferralForms,
              PermissionsEnum.ChangeProgramReferralForm,
            ],
            actions: [
              {
                onClick() {
                  history.push(`/referral-form/${data.referralForm.id}`);
                },
                condition: data.referralForm,
                permissions: [PermissionsEnum.ViewReferralForms],
                icon: <OpenInNew />,
                tooltip: "View Referral Form",
              },
            ],
          },
          {
            name: "Academic Dean",
            value: data ? data.programInfo?.academicDean || "Default" : null,
            permissions: [PermissionsEnum.ViewProgramInfo],
            edit: {
              editPermissions: [PermissionsEnum.ModifyProgramInfo],
              form: {
                title: "Change Academic Program Dean",
                apiRequest: (formData: any) =>
                  api.changeProgramInfo(+id, {
                    registrar: data?.programInfo?.registrar,
                    academicDean: formData.academicDean,
                  }),
                inputs: [
                  {
                    type: "text",
                    name: "Academic Dean Name",
                    keyName: "academicDean",
                    required: false,
                  },
                ],
                successMessage: () => {
                  refresh();
                  return "Program Dean changed successfully!";
                },
                errorMessage: handleResponseError,
              },
            },
          },
          {
            name: "Registrar",
            value: data ? data.programInfo?.registrar || "Default" : null,
            permissions: [PermissionsEnum.ViewProgramInfo],
            edit: {
              editPermissions: [PermissionsEnum.ModifyProgramInfo],
              form: {
                title: "Change Program Registrar",
                apiRequest: (formData: any) =>
                  api.changeProgramInfo(+id, {
                    registrar: formData.registrar,
                    academicDean: data?.programInfo?.academicDean,
                  }),
                inputs: [
                  {
                    type: "text",
                    name: "Registrar Name",
                    keyName: "registrar",
                    required: false,
                  },
                ],
                successMessage: () => {
                  refresh();
                  return "Registrar changed successfully!";
                },
                errorMessage: handleResponseError,
              },
            },
          },
        ]}
      />
      <br />
      <br />
      <CollectionComponent
        title="Program Courses"
        loading={programCourseLoading}
        data={programCourseData || []}
        backendPagination
        backendSearch
        pagesCount={programCoursePagesCount}
        page={programCoursePage}
        pageSize={programCoursePageSize}
        searchText={programCourseSearch}
        onPageChange={programCourseOnPageChange}
        onPageSizeChange={programCourseOnPageSizeChange}
        onSearchChange={programCourseOnSearchChange}
        viewDisabledInSeparateTab
        onHideChange={programCourseOnHideChange}
        viewDisabledPermission={PermissionsEnum.ViewCoursePrograms}
        columns={[
          {
            title: "Code",
            field: "course.code",
            editable: "never",
          },
          {
            title: "Core",
            field: "optional",
            render: (row) => (row.optional ? "No" : "Yes"),
            editComponent: (row) => {
              return InputFactory("checkbox", {
                id: "program-course-is-core",
                errors,
                control,
                name: "core",
                onChange: (event: any) => {
                  return (row.rowData.optional = !event);
                },
                defaultValue: !row.value,
              });
            },
          },
          {
            title: "Credit Hours",
            field: "course.creditHours",
            editable: "never",
          },
          {
            title: "English name",
            field: "enName",
            editable: "never",
          },
          // {
          //   title: "English description",
          //   field: "enDescription",
          // },
          {
            title: "Arabic name",
            field: "arName",
            editable: "never",
          },
          {
            title: "Graduate",
            field: "isUnderGraduate",
            render: (row) => (row.isUnderGraduate ? "No" : "Yes"),
            editComponent: (props) =>
              InputFactory("checkbox", {
                control,
                errors,
                id: "program-course-is-undergraduate",
                name: "Graduate",
                customControlName: "isUnderGraduate",
                onChange: (e: any) => {
                  props.rowData.isUnderGraduate = !e;
                },
                defaultValue: !props?.value,
              }),
          },
          // {
          //   title: "Arabic description",
          //   field: "arDescription",
          // },
        ]}
        mainPermission={PermissionsEnum.EditProgramCourse}
        noDelete
        onRowUpdate={async (input: any, oldData: any) => {
          await api.updateProgramCourse(oldData.id, input);
          programCourseRefresh();
        }}
        itemName="course"
        disableKey="archived"
        actions={[
          {
            icon: "delete",
            tooltip: "Archive program course",
            onClick: async (_e, data: any) => {
              await api.removeProgramCourse(data.id);
              programCourseRefresh();
            },
            condition: (data: any) => !data.archived,
            requiredPermission: PermissionsEnum.ArchiveProgramCourse,
          },
          {
            icon: "undo",
            tooltip: "Restore program course",
            onClick: async (_e: any, data: any) => {
              await api.restoreProgramCourse(data.id);
              programCourseRefresh();
            },
            condition: (data: any) => data.archived,
            requiredPermission: PermissionsEnum.RestoreProgramCourse,
          },
          {
            onClick: (_: any, row: any) =>
              history.push(`/course/${row.course?.id}`),
            icon: () => <OpenInNew />,
            tooltip: "View course",
            requiredPermission: false,
          },
        ]}
      />
      {/* <ComponentGuard
        requiredPermissions={[PermissionsEnum.ViewConcentrations]}
      > */}
      <CollectionComponent
        title="Program Concentrations"
        loading={concentrationLoading}
        data={concentrationData || []}
        backendPagination
        backendSearch
        pagesCount={concentrationPagesCount}
        page={concentrationPage}
        pageSize={concentrationPageSize}
        searchText={concentrationSearch}
        onPageChange={concentrationOnPageChange}
        onPageSizeChange={concentrationOnPageSizeChange}
        onSearchChange={concentrationOnSearchChange}
        mainPermission={PermissionsEnum.EditConcentration}
        viewDisabledInSeparateTab
        onHideChange={concentrationOnHideChange}
        viewDisabledPermission={PermissionsEnum.ViewConcentrations}
        noDelete
        onRowUpdate={async (input: any, oldData: any) => {
          await api.updateConcentration(oldData.id, input);
          concentrationRefresh();
          enqueueSnackbar("Concentration edited successfully!", {
            variant: "success",
          });
        }}
        columns={[
          {
            title: "Code",
            field: "code",
            editable: "never",
          },
          {
            title: "Credit Hours",
            field: "creditHours",
            // editable: 'never', // temp
          },
          {
            title: "English name",
            field: "enName",
          },
          {
            title: "English description",
            field: "enDescription",
          },
          {
            title: "Arabic name",
            field: "arName",
          },
          {
            title: "Arabic description",
            field: "arDescription",
          },
        ]}
        itemName="concentration"
        disableKey="archived"
        actions={[
          {
            icon: "delete",
            tooltip: "Archive concentration",
            onClick: async (_e, data: any) => {
              const confirmed = window.confirm(
                "Are you sure you want to archive this concentration?"
              );
              if (!confirmed) return;
              await api.removeConcentration(data.id);
              refresh();
            },
            requiredPermission: PermissionsEnum.ArchiveConcentration,
            condition: (data: any) => !data.archived,
          },
          {
            icon: "undo",
            tooltip: "Restore concentration",
            onClick: async (_e: any, data: any) => {
              const confirmed = window.confirm(
                "Are you sure you want to restore this concentration?"
              );
              if (!confirmed) return;
              await api.restoreConcentration(data.id);
              refresh();
            },
            condition: (data: any) => data.archived,
            requiredPermission: PermissionsEnum.RestoreConcentration,
          },
          {
            onClick: (_: any, row: any) =>
              history.push(`/concentration/${row.id}`),
            icon: () => <OpenInNew />,
            tooltip: "Open concentration",
            requiredPermission: PermissionsEnum.ViewConcentration,
          },
        ]}
      />
      {/* </ComponentGuard> */}
      <ComponentGuard
        requiredPermissions={[PermissionsEnum.ViewProgramOfferings]}
      >
        <CollectionComponent
          title="Program Offerings"
          loading={programOfferingsLoading}
          data={programOfferingsData || []}
          viewDisabledInSeparateTab
          onHideChange={programOfferingsOnHideChange}
          backendPagination
          backendSearch
          pagesCount={programOfferingsPagesCount}
          page={programOfferingsPage}
          pageSize={programOfferingsPageSize}
          searchText={programOfferingsSearch}
          onPageChange={programOfferingsOnPageChange}
          onPageSizeChange={programOfferingsOnPageSizeChange}
          onSearchChange={programOfferingsOnSearchChange}
          mainPermission={PermissionsEnum.ArchiveProgramOffering}
          onRowDelete={async (oldData: any) => {
            if (oldData.archived) {
              alert("Offering already deleted");
              return;
            }
            await api.removeOffering(oldData.id);
            programOfferingsRefresh();
          }}
          noEdit
          columns={[
            {
              title: "Semester",
              field: "semester.semester",
            },
            {
              title: "Year",
              field: "semester.year",
            },
            {
              title: "Credit Hours",
              field: "creditHours",
            },
          ]}
          itemName="programOffering"
          disableKey="archived"
          actions={[
            {
              icon: "undo",
              tooltip: "Restore offering",
              onClick: async (_e: any, data: any) => {
                if (!data.archived) {
                  alert("Offering not deleted");
                  return;
                }
                const confirmed = window.confirm(
                  "Are you sure you want to restore this offering?"
                );
                if (!confirmed) return;
                await api.restoreOffering(data.id);
                programOfferingsRefresh();
              },
              requiredPermission: PermissionsEnum.RestoreProgramOffering,
            },
            {
              onClick: (_: any, row: any) =>
                history.push(`/programs/offerings/${row.id}`),
              icon: () => <OpenInNew />,
              tooltip: "View program offering",
              requiredPermission: PermissionsEnum.ViewProgramOffering,
            },
          ]}
        />
      </ComponentGuard>
      <ComponentGuard
        requiredPermissions={[PermissionsEnum.ViewProgramGradingSchemas]}
      >
        <CollectionComponent
          title="Grading schemas"
          loading={loading}
          viewOnly
          data={data.gradingSchemas || []}
          columns={[
            {
              title: "Name",
              field: "gradingSchema.name",
            },
            {
              title: "Starting from",
              render: RenderDate(["createdAt"]),
              field: "createdAt",
              editable: "never",
            },
          ]}
          itemName="grading schema"
          disableKey="archived"
          actions={[
            {
              onClick: (_: any, row: any) =>
                history.push(`/grading-schema/${row.gradingSchema.id}`),
              icon: () => <OpenInNew />,
              tooltip: "View grading schema",
              requiredPermission: PermissionsEnum.ViewGradingSchemas,
            },
          ]}
        />
      </ComponentGuard>

      <CollectionComponent
        title={t("ADMISSION_REQUIREMENTS")}
        loading={programAdmissionRequirementLoading}
        data={programAdmissionRequirementData || []}
        backendPagination
        backendSearch
        pagesCount={programAdmissionRequirementPagesCount}
        page={programAdmissionRequirementPage}
        pageSize={programAdmissionRequirementPageSize}
        searchText={programAdmissionRequirementSearch}
        onPageChange={programAdmissionRequirementOnPageChange}
        onPageSizeChange={programAdmissionRequirementOnPageSizeChange}
        onSearchChange={programAdmissionRequirementOnSearchChange}
        mainPermission={PermissionsEnum.RemoveAdmissionRequirementFromProgram}
        onRowDelete={async (oldData: any) => {
          await api.removeAdmissionRequirementFromProgram(+id, oldData.id);
          programAdmissionRequirementRefresh();
        }}
        deleteText="Are you sure you want to remove this admission requirement from the program?"
        noEdit
        columns={[
          {
            title: t("NAME"),
            field: "name",
          },
          {
            title: "English Text",
            field: "enText",
            cellStyle: { whiteSpace: "pre-line" },
          },
          {
            title: "Arabic Text",
            field: "arText",
            cellStyle: { whiteSpace: "pre-line" },
          },
          {
            title: "Links",
            field: "attachments",
            render: (row) => row.attachments?.join(", ") || "-",
          },
        ]}
        itemName="admissionRequirement"
        actions={[
          {
            onClick: (_: any, row: any) =>
              history.push(`/admissionRequirement/${row.id}`),
            icon: () => <OpenInNew />,
            tooltip: "View admission requirement",
            render: (p: any) => <MTableAction {...p} />,
          },
        ]}
      />

      <CollectionComponent
        title={t("FAQ_GROUPS")}
        loading={programFaqGroupLoading}
        data={programFaqGroupData || []}
        backendPagination
        backendSearch
        pagesCount={programFaqGroupPagesCount}
        page={programFaqGroupPage}
        pageSize={programFaqGroupPageSize}
        searchText={programFaqGroupSearch}
        onPageChange={programFaqGroupOnPageChange}
        onPageSizeChange={programFaqGroupOnPageSizeChange}
        onSearchChange={programFaqGroupOnSearchChange}
        deletePermission={PermissionsEnum.RemoveFAQGroupFromProgram}
        onRowDelete={async (oldData: any) => {
          await api.removeFAQGroupFromProgram(+id, oldData.id);
          enqueueSnackbar("FAQ Group removed from program successfully!", {
            variant: "success",
          });
          programFaqGroupRefresh();
        }}
        deleteText="Are you sure you want to remove this FAQ Group from the program?"
        noEdit
        columns={[
          {
            title: "Name",
            field: "name",
          },
          // {
          //   title: "English Answer",
          //   field: "enAnswer",
          // },
          // {
          //   title: "Arabic Question",
          //   field: "arQuestion",
          // },
          // {
          //   title: "Arabic Answer",
          //   field: "arAnswer",
          // },
        ]}
        itemName="faqGroup"
        actions={[
          {
            onClick: (_: any, row: any) => history.push(`/faqGroup/${row?.id}`),
            icon: () => <OpenInNew />,
            tooltip: "View faq group",
            // requiredPermission: PermissionsEnum.ViewFAQGroup,
          },
        ]}
      />

      <CollectionComponent
        title={t("VIDEOS")}
        loading={programVideoLoading}
        data={programVideoData || []}
        backendPagination
        backendSearch
        pagesCount={programVideoPagesCount}
        page={programVideoPage}
        pageSize={programVideoPageSize}
        searchText={programVideoSearch}
        onPageChange={programVideoOnPageChange}
        onPageSizeChange={programVideoOnPageSizeChange}
        onSearchChange={programVideoOnSearchChange}
        mainPermission={PermissionsEnum.RemoveVideoFromProgram}
        onRowDelete={async (oldData: any) => {
          await api.removeVideoFromProgram(+id, oldData.id);
          programVideoRefresh();
        }}
        deleteText="Are you sure you want to remove this video from the program?"
        noEdit
        columns={[
          {
            title: t("NAME"),
            field: "name",
          },
          {
            title: t("LINK"),
            field: "url",
          },
        ]}
        itemName="video"
        actions={[
          {
            onClick: (_: any, row: any) => history.push(`/videos`),
            icon: () => <OpenInNew />,
            tooltip: "View videos",
            requiredPermission: PermissionsEnum.ViewVideos,
          },
        ]}
      />
    </React.Fragment>
  );
};

export default Program;
